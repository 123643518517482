import Vue from 'vue'
import VueRouter from 'vue-router'
// import Search from '../views/Search.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Search',
  //   component: Search,
  //   link: true
  // },
  {
    path: '/Newsletter',
    name: 'Newsletter',
    component: () => import('../views/Newsletter.vue'),
    link: true
  },
  {
    path: '/Archive',
    name: 'Archive',
    component: () => import('../views/Archive.vue'),
    link: true
  },
  {
    path: '/',
    name: 'About',
    component: () => import('../views/About.vue'),
    link: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
